// The optional cleanup function will be used to store a reference to the function that cleans up the scroll event listener.
interface HTMLElementWithCleanup extends HTMLElement {
  _onScrollCleanup?: () => void;
}

const ScrollyDirective = {
  mounted(el: HTMLElementWithCleanup) {
    // Throttle function limits the rate at which a function can fire.
    // The function func will be called at most once every limit milliseconds.
    const throttle = (func: Function, limit: number) => {
      // Stores the timeout ID for the last function call.
      let lastFunc: any;
      // Stores the timestamp of the last execution.
      let lastRan: number;
      return function (...args: any[]) {
        // If the function hasn't been run yet...
        if (!lastRan) {
          // ...run the function immediately.
          func(...args);
          lastRan = Date.now();
        } else {
          // Clear the previous timeout to avoid multiple queued executions.
          clearTimeout(lastFunc);
          lastFunc = setTimeout(() => {
            // Ensure that the function only runs if the time since last run is greater than limit.
            if (Date.now() - lastRan >= limit) {
              func(...args);
              lastRan = Date.now();
            }
            // Schedule the function to run after the remaining wait time.
          }, limit - (Date.now() - lastRan));
        }
      };
    };

    // This function updates the element's classes based on its position in the viewport.
    const updateElementClass = () => {
      const vpHeight = window.innerHeight;
      const bounds = el.getBoundingClientRect();
      const inFrame = bounds.top < vpHeight && bounds.bottom > 0;
      const belowFrame = bounds.top >= vpHeight;
      const aboveFrame = bounds.bottom <= 0;

      // Clear all classes and then add relevant ones
      el.classList.remove(
        'in-frame',
        'below-frame',
        'above-frame',
        'going-up',
        'going-down'
      );

      if (inFrame) {
        el.classList.add('in-frame');
      } else if (belowFrame) {
        el.classList.add('below-frame', 'going-up');
      } else if (aboveFrame) {
        el.classList.add('above-frame', 'going-down');
      }
    };

    // Create a throttled version of the updateElementClass function to limit its execution rate.
    const handleScroll = throttle(updateElementClass, 100);

    // Attach the throttled scroll handler to the window's scroll event.
    window.addEventListener('scroll', handleScroll);
    updateElementClass();

    // Assign cleanup function to custom property
    el._onScrollCleanup = () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },

  unmounted(el: HTMLElementWithCleanup) {
    if (el._onScrollCleanup) el._onScrollCleanup();
  }
};

export default ScrollyDirective;
