<template>
  <div id="chatbot" v-if="showChatbot">
    <div
      id="bot"
      v-if="chatWindowOpen"
      :class="{ 'hide-chat': chatWindowHidden }"
    >
      <div v-if="showChat">
        <div class="header">
          <h2>Sonora Quest Chat</h2>
          <a @click.prevent="chatWindowTog()" href="#" class="close"></a>
        </div>
        <ChatbotScreen></ChatbotScreen>
      </div>
      <div class="hipaa" v-if="showHipaa">
        <div class="header">
          <h2>HIPAA Disclaimer</h2>
          <a @click.prevent="chatWindowTog()" href="#" class="close"></a>
        </div>
        <div class="text">
          <div class="scroll">
            <div v-html="chatbotMessage"></div>
          </div>
          <a @click.prevent="agree()" href="" class="agree btn btn3">Agree</a>
        </div>
      </div>
    </div>
    <a v-if="showCta" @click="showChatWindow()" href="#" id="chatbot-cta">
      <i class="fa fa-comments"></i>
      <span>Chat With Us</span>
    </a>
  </div>
</template>

<script setup lang="ts">
// #region GENERAL IMPORTS
import { onMounted, ref, computed } from 'vue';
import ChatbotScreen from '@/components/Chatbot/ChatbotScreen.vue';
// #endregion GENERAL IMPORTS

// #region PAGE DATA
import { usePageData } from '@/stores/content-cache';
const pageData = ref(usePageData());
// #endregion PAGE DATA

// #region PROPS AND DATA
const chatWindowOpen = ref(false);
const chatWindowHidden = ref(false);
const showChat = ref(false);
const showHipaa = ref(true);

const isInternetExplorer = computed(() => {
  return /MSIE|Trident/.test(navigator.userAgent);
});

const chatWindowOpenHidden = computed(() => {
  return chatWindowOpen.value && chatWindowHidden.value;
});

const showCta = computed(() => {
  if (!chatWindowOpen.value || chatWindowOpenHidden.value) return true;
  else return false;
});

const showChatbot = computed(() => {
  if (isInternetExplorer.value) return false;
  if (pageData.value.settings?.chatbot?.chatbotOptions === 'Disable Globally')
    return false;
  if (pageData.value.settings?.chatbot?.chatbotOptions === 'Enable Globally')
    return true;
  if (pageData.value.settings.page.showChatbot) return true;
  else return false;
});
// #endregion PROPS AND DATA

const checkHipaa = () => {
  var cbhipaa = sessionStorage.getItem('cbhipaa');
  if (cbhipaa) {
    showHipaa.value = false;
    showChat.value = true;
  }
};

const chatbotMessage = computed(() => {
  return pageData.value.settings?.chatbot?.chatbotMessage
    ? pageData.value.settings.chatbot.chatbotMessage
    : `We're sorry, an error has occurred retrieving the HIPAA disclaimer. Please try again later.`;
});
const agree = () => {
  showHipaa.value = false;
  showChat.value = true;
  sessionStorage.setItem('cbhipaa', 'true');
};

const chatWindowTog = () => {
  chatWindowHidden.value = !chatWindowHidden.value;
};

const showChatWindow = () => {
  if (!chatWindowOpen.value) chatWindowOpen.value = true;
  else {
    if (!chatWindowHidden.value) chatWindowHidden.value = true;
    else chatWindowHidden.value = false;
  }
};

onMounted(() => checkHipaa());
</script>
