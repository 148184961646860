import type { Directive } from 'vue';

/**
 * A custom Vue.js directive that detects and handles click events occurring outside
 * a specified element. When a click outside the element is detected, the directive
 * executes a provided handler function.
 *
 * @example
 * <template>
 *   <div v-click-outside="handleClickOutside">Content</div>
 * </template>
 *
 * <script setup lang="ts">
 *   const handleClickOutside = (): void => {
 *     Do something;
 *   };
 * </script>
 */
const ClickOutside: Directive = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: MouseEvent) {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  }
};

export default ClickOutside;
