<template>
  <button
    @click.prevent="submit"
    :class="`btn ${classes} ${props.loading ? 'loading' : ''} spinner`"
    :disabled="props.submitDisabled"
    :loading="props.loading"
    type="submit"
  >
    <AppSpinner class="spinner" />
    <span class="slot">{{ buttonText }}</span>
  </button>
</template>

<script setup lang="ts">
import { type PropType } from 'vue';
import AppSpinner from '@/components/General/AppSpinner.vue';

const props = defineProps({
  submitDisabled: {
    type: Boolean as PropType<boolean>,
    required: true
  },
  loading: {
    type: Boolean as PropType<boolean>,
    required: true
  },
  buttonText: {
    type: String as PropType<string>,
    required: true
  },
  classes: {
    type: String as PropType<string>,
    required: false
  }
});

const emit = defineEmits<{
  (event: 'submit'): void;
}>();

const submit = () => {
  emit('submit');
};

// Example implementation:
// <SpinnerButton
//   :loading="loading"
//   :submitDisabled="submitDisabled"
//   :buttonText="submitButtonText"
//   @submit="checkAddress"
// ></SpinnerButton>
</script>
