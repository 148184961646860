<template>
  <div class="hide-print">
    <FocusFooter v-if="useFocus"></FocusFooter>
    <DefaultFooter v-else :pageData="pageData"></DefaultFooter>
  </div>
</template>

<script setup lang="ts">
import FocusFooter from '@/components/AppFooter/FocusFooter.vue';
import DefaultFooter from '@/components/AppFooter/DefaultFooter.vue';
import { storeToRefs } from 'pinia';

import { useProfileStore } from '@/stores/profile';
const profileStore = useProfileStore();
const { useFocus } = storeToRefs(profileStore);

import type { PageData } from '@/types/content';
defineProps<{
  pageData?: PageData | null;
}>();
</script>
