<template>
  <div id="webchat" role="main"></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

interface WebChat {
  createStore: (initialState: any, middleware: any) => any;
  createDirectLine: (options: { token: string }) => any;
  renderWebChat: (options: any, element: HTMLElement | null) => void;
}

interface ConversationInfo {
  token: string;
}

const fontFamily = (fonts: string[]) => {
  return fonts.map(font => `'${font}'`).join(', ');
};
const loadWebChatScript = async (): Promise<WebChat> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src =
      'https://cdn.botframework.com/botframework-webchat/latest/webchat.js';
    script.onload = () => resolve((window as any).WebChat);
    script.onerror = event =>
      reject(new Error(`Failed to load Web Chat script.`));
    document.head.appendChild(script);
  });
};

const fetchDirectLineToken = async (botId: string): Promise<string> => {
  const botUrl = `https://powerva.microsoft.com/api/botmanagement/v1/directline/directlinetoken?botId=${botId}`;
  try {
    const response = await fetch(botUrl, {
      method: 'GET',
      headers: {
        // If authentication is needed, add the required headers here
      }
    });
    const data = await response.json();
    if ('token' in data) {
      return data.token;
    } else {
      throw new Error('Token not found in response');
    }
  } catch (error) {
    console.error('Fetching Direct Line token failed:', error);
    throw error;
  }
};

const loadChatBot = async () => {
  const WebChat: WebChat = await loadWebChatScript();
  const directLineToken: string = await fetchDirectLineToken(
    'ae646b2b-f751-4fd1-a961-ea1ff6c14819'
  );
  const styleOptions = {
    backgroundColor: '#ECEFF1',
    primaryFont: fontFamily(['Roboto', 'sans-serif']),
    botAvatarBackgroundColor: 'white',
    botAvatarInitials: 'dex',
    botAvatarImage: '/assets/chatbot-bot-avatar.png',
    userAvatarBackgroundColor: 'white',
    userAvatarImage: '/assets/chatbot-user-avatar.png',
    userAvatarInitials: 'you',
    hideUploadButton: true,
    suggestedActionBackgroundColor: '#198E88',
    suggestedActionBorderRadius: '5px',
    suggestedActionBorderWidth: '0',
    suggestedActionTextColor: 'white'
  };

  // Define the store with middleware, providing appropriate types or using any with caution
  const store = WebChat.createStore(
    {},
    ({ dispatch }: { dispatch: Function }) =>
      (next: Function) =>
      (action: any) => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            meta: { method: 'keyboard' },
            payload: {
              activity: {
                channelData: { postBack: true },
                name: 'startConversation',
                type: 'event'
              }
            },
            type: 'DIRECT_LINE/POST_ACTIVITY'
          });
        }
        return next(action);
      }
  );

  WebChat.renderWebChat(
    {
      directLine: WebChat.createDirectLine({ token: directLineToken }),
      store,
      styleOptions
    },
    document.getElementById('webchat')
  );
};
onMounted(loadChatBot);
</script>
