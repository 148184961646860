<template>
  <div>
    <div class="hide-print">
      <FocusHeader v-if="useFocus"></FocusHeader>
      <DefaultHeader v-else :pageData="pageData"></DefaultHeader>
    </div>

    <div class="show-print">
      <img
        id="logo-for-print"
        src="/assets/logo.svg"
        alt="Sonora Quest Laboratory"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FocusHeader from '@/components/AppHeader/FocusHeader.vue';
import DefaultHeader from '@/components/AppHeader/DefaultHeader.vue';
import { storeToRefs } from 'pinia';

import { useProfileStore } from '@/stores/profile';
const profileStore = useProfileStore();
const { useFocus } = storeToRefs(profileStore);

import type { PageData } from '@/types/content';
defineProps<{
  pageData?: PageData | null;
}>();
</script>
