<template>
  <footer id="footer">
    <div class="box box1600">
      <div class="left">
        <a id="logo" href="/">
          <img src="/assets/logo.svg" alt="Sonora Quest Laboratory" />
        </a>
        <nav id="mobi-footer-nav">
          <ul class="nav">
            <li
              v-for="(route, i) in pageData?.navigation.footer"
              :key="`foot${i}`"
              :class="{ active: isActive(i) }"
            >
              <a @click.prevent="handleMobiFooterClick(i)" href="#">
                {{ route.title ?? route.name }}
              </a>
              <ul v-if="hasChildren(route)">
                <li
                  v-for="(child, i) in route.children"
                  :key="`${route.name}-sub${i}`"
                >
                  <router-link :to="child.url" @click="removeActive()">
                    {{ child.title ?? child.name }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <ul class="soc">
          <li
            v-for="(soc, i) in pageData?.settings.socialLinks"
            :key="`soc${i}`"
          >
            <a :href="soc.link" target="blank">
              <span v-html="soc.icon"></span>
              <span class="visually-hidden">{{ soc.name }}</span>
            </a>
          </li>
        </ul>
        <p class="foot-note">
          &copy;{{ currentYear }} Sonora Quest Laboratories, All Rights
          Reserved.<br />
          Digital Transformation by
          <a href="http://www.i-ology.com/" target="_blank">I-ology</a>&reg;.
        </p>
      </div>
      <div class="right">
        <nav id="footer-nav">
          <ul class="nav">
            <li
              v-for="(route, i) in pageData?.navigation.footer"
              :key="`foot${i}`"
            >
              <div class="section">{{ route.title ?? route.name }}</div>
              <ul v-if="hasChildren(route)">
                <li
                  v-for="(child, i) in route.children"
                  :key="`${route.name}-sub${i}`"
                >
                  <router-link :to="child.url">
                    {{ child.title ?? child.name }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
//#region GENERAL IMPORTS
import type { PageData } from '@/types/content';
import { onMounted, onUnmounted, ref } from 'vue';
import { format } from 'date-fns';
const currentYear = format(new Date(), 'yyyy');
defineProps<{
  pageData?: PageData | null;
}>();
//#endregion GENERAL IMPORTS

//#region DYNAMIC BODY SIZE
onMounted(() => {
  const footer = document.getElementById('footer');
  const updateCustomProperties = () => {
    if (footer) {
      document.documentElement.style.setProperty(
        '--footer_height',
        `${footer.clientHeight}px`
      );
    } else {
      document.documentElement.style.setProperty('--footer_height', `0px`);
    }
  };
  updateCustomProperties();
  const resizeObserver = new ResizeObserver(updateCustomProperties);
  if (footer) resizeObserver.observe(footer);
  onUnmounted(() => {
    if (footer) resizeObserver.unobserve(footer);
    resizeObserver.disconnect();
  });
});
//#endregion DYNAMIC BODY SIZE

//#region MOBILE FOOTER NAV
interface Route {
  children?: any[];
}
let hasChildren = (route: Route) => {
  return Array.isArray(route.children) && route.children.length;
};
const activeNav = ref<number[]>([]);
const handleMobiFooterClick = (i: number) => {
  if (activeNav.value.includes(i)) {
    activeNav.value = activeNav.value.filter(item => item !== i);
  } else {
    activeNav.value.push(i);
  }
};
const isActive = (i: number): boolean => {
  return activeNav.value.includes(i);
};
const removeActive = () => {
  activeNav.value = [];
};
//#endregion MOBILE FOOTER NAV
</script>
